.loginLoadingPage {
    width: 100vw;
    background: linear-gradient(360deg, #FFF7EB 0%, #FFF3E3 67%, #FFDFB3 100%);
}

.loginLoading_nav {
    padding-left: 56px;
    padding-top: 36px;
    height: 48px;
    line-height: 48px;
    font-size: 36px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #1C1D1F;
}

.loginLoading_nav>img {
    width: 48px;
    height: 48px;
    float: left;
    margin-left: 10px;
}

.loginLoading_title {
    margin-top: 102px;
    width: 100%;
}

.loginLoading_title_top {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: #1C1D1F;
    line-height: 48px;
    font-weight: 600;
}

.loginLoading_title_bottom {
    width: 100%;
    text-align: center;
    font-size: 28px;
}

.loginLoading_title_bar {
    margin-top: 18px;
    width: 496px;
    height: 32px;
    position: relative;
    margin: 18px auto 0;
}

.loginLoading_title_bar_imgBox {
    width: 502px;
    height: 32px;
}

.loginLoading_title_bar_imgHead {
    width: 38px;
    height: 38px;
    position: absolute;
    top: -7px;
}

.loginLoading_title_bar_bar {
    height: 16.5px;
    background-color: rgba(253, 118, 22, 1);
    position: absolute;
    top: 8.8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    left: 17px;
    /* background: linear-gradient(90deg, #FFF690 0%, #FF3232 100%); */
    background: linear-gradient(270deg, #F0AB57 0%, #FFF6DF 100%);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,0.64), inset 0px -1px 2px 0px rgba(161,109,74,0.75);
}

.loginLoading_title_tipsNum {
    font-size: 26px;
    font-weight: 600;
    color: #A16D4A;
    line-height: 39px;
    text-align: center;
}

.loginLoading_card {
    width: 100vw;
    height: 922px;
    position: relative;
    overflow: hidden;
}

.loginLoading_card_img {
    width: 750px;
    height: 922px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loginLoading_userInfo {
    width: 450px;
    height: 181px;
    position: absolute;
    left: 150px;
    top: 530px;
    z-index:10
}
.loginLoading_userInfo span {
    font-size: 24px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    color: #FFCCBE;
    display: inline-block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loginLoading_userInfo span:first-child {
    margin-right: 36px;
}

.loginLoading_userInfo_top {
    margin-top: 80px;
    box-sizing: border-box;
    padding-left: 36px;
}
.loginLoading_userInfo_top span:last-child { 
    margin-left: 40px;
}
.loginLoading_userInfo_bottom {
    margin-top: 60px;
    box-sizing: border-box;
    padding-left:40px;
}
.loginLoading_userInfo_bottom span:last-child {
    margin-left: 36px;
}
.loginLoading_card_mask {
    position: absolute;
    top: 0;
    height: 922px;
    width: 100%;
    left: -100%;
    animation: anis 1s infinite linear;
}

@keyframes anis {
    from {
        left: -100%;
    }to{
        left: 100%;
    }
}

.loginLoading_fail_img {
    width: 435px;
    height: 485px;
    display: block;
    margin: 205px auto 0;
}

.loginLoading_fail_title {
    font-size: 36px;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: rgba(161, 109, 74, 1);
    line-height: 51px;
    text-align: center;
    margin-top: 50px;
}

.loginLoading_fail_tips {
    width: 620px;
    height: 249px;
    background: rgba(255, 223, 180, 0.4);
    border-radius: 14px;
    margin: 46px auto 0;
    box-sizing: border-box;
    padding: 28px 58px 0;
    text-align: center;
}

.loginLoading_fail_tips>span {
    font-size: 31px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #A16D4A;
    line-height: 43px;
}

.loginLoading_fail_tips>img {
    font-size: 32px;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #A16D4A;
    line-height: 45px;
    text-align: center;
    margin-top: 28px;
    width: 267px;
    height: 72px;
}