.toastPc_page{
    /* display: inline-block; */
    width: auto;
    box-sizing: border-box;
    padding: .5rem 1.5rem;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 1.25rem;
    color: #fff;
    border-radius: 1.375rem;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.toastPc_page>img{
    width: 1.625rem;
    height: 1.625rem;
    margin-right: .5rem;
}