.loginPcLoading_mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}

.loginPcLoadingPage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 59.375rem;
    height: 41rem;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01f95oRd1DsA8fyJw85_!!6000000000271-2-tps-950-656.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 2.125rem;
}

.loginPcLoading_close {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    right: -4.25rem;
}

.loginPcLoading_title {
    width: 100%;
}

.loginPcLoading_title_top {
    font-size: 1.5rem;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    color: #1C1D1F;
    line-height: 2.0625rem;
    text-align: center;
}

.loginPcLoading_title_bottom {
    font-size: 1.125rem;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    color: rgba(28, 29, 31, 0.8);
    line-height: 2.0625rem;
    text-align: center;
}

.loginPcLoading_title_bar {
    position: relative;
    width: 22.75rem;
    height: 2.25rem;
    margin: .4375rem auto 0;
}

.loginPcLoading_title_bar_imgBox {
    height: 2.25rem;
}

.loginPcLoading_title_bar_imgHead {
    width: 1.875rem;
    position: absolute;
    top: -0.25rem;
}

.loginPcLoading_title_bar_bar {
    height: .6875rem;
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
    position: absolute;
    left: 1.3125rem;
    top: .75rem;
    background: linear-gradient(270deg, #F0AB57 0%, #FFF6DF 100%);
    box-shadow: inset 0px 1px 2px 0px rgba(255,255,255,0.64), inset 0px -1px 2px 0px rgba(161,109,74,0.75);
}

.loginPcLoading_title_tipsNum {
    font-size: 1.125rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #B92E35;
    line-height: 1.5625rem;
    text-align: center;
}

.loginPcLoading_card {
    width: 100%;
    height: 30.625rem;
    position: relative;
    overflow: hidden;
}

.loginPcLoading_card_img {
    width: 27.25rem;
    height: 30.375rem;
    margin: auto;
    display: block;
}

.loginPcLoading_userInfo {
    width: 16.25rem;
    height: 9.625rem;
    position: absolute;
    left: 22.125rem;
    top: 17.125rem;
}

.loginPcLoading_userInfo span {
    font-size: .875rem;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    color: #FFCCBE;
    line-height: 1.25rem;
    display: inline-block;
    width: 5.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loginPcLoading_userInfo span:first-child {
    margin-right: 2.5rem;
}

.loginPcLoading_userInfo_top {
    margin-top: 2.75rem;
    box-sizing: border-box;
    padding-left: .875rem;
}

.loginPcLoading_userInfo_bottom {
    margin-top: 2.5rem;
    box-sizing: border-box;
    padding-left: .875rem;
}

.loginPcLoading_card_mask {
    position: absolute;
    top: 0;
    height: 30.625rem;
    width: 100%;
    left: -100%;
    animation: anis 1s infinite linear;
}

@keyframes anis {
    from {
        left: -100%;
    }

    to {
        left: 100%;
    }
}

.loginPcLoading_fail_img {
    width: 15rem;
    display: block;
    margin: 2.25rem auto 0;
}

.loginPcLoading_fail_title {
    font-size: 1.25rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: rgba(161, 109, 74, 1);
    line-height: 1.75rem;
    text-align: center;
    margin-top: .625rem;
}

.loginPcLoading_fail_tips {
    width: 42.625rem;
    height: 8.875rem;
    background: rgba(255, 223, 180, 0.4);
    border-radius: .625rem;
    margin: 4.875rem auto 0;
    box-sizing: border-box;
    padding: 1.625rem 11.875rem 0;
    text-align: center;
}

.loginPcLoading_fail_tips>span {
    font-size: .875rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: rgba(161, 109, 74, 1);
    line-height: 1.25rem;
}

.loginPcLoading_fail_tips>img {
    width: 7.625rem;
    height: 2.125rem;
    margin: .875rem auto;
    display: block;
    cursor: pointer;
}