.modalPc_mask{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}
.modalPc_box {
    width: 28.4375rem;
    height: 16.25rem;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01JhhC4R1Rh5BYy0NK4_!!6000000002142-2-tps-912-520.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.modalPc_title {
    font-size: 1.25rem;
    font-family: AlibabaPuHuiTi_2_75_SemiBold;
    color: #FFFFFF;
    line-height: 1.75rem;
    text-align: center;
    margin-top: 1.5rem;
}

.modalPc_contents {
    width: 100%;
    height: calc(100% - 7.1875rem);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: .875rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #FFF7E3;
    line-height: 1.25rem;
    box-sizing: border-box;
    padding: 0 5rem;
}

.modalPc_btn {
    width: 6.75rem;
    height: 2.125rem;
    line-height: 2.125rem;
    text-align: center;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN014gQzd71LPK8LAhOAb_!!6000000001291-2-tps-280-80.png") no-repeat;
    background-size: 100% 100%;
    font-size: 1rem;
    font-family: COCA-COLACAREFONTKAITI--GB1-0, COCA-COLACAREFONTKAITI--GB1;
    font-weight: normal;
    color: #FFEEC3;
    margin: auto;
    text-shadow: 0 .1875rem 0 rgba(171,31,0,0.4), 0 .0625rem 0 rgba(255,255,255,0.71);
    cursor: pointer;
}