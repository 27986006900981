.GamesToastPc_page {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: 2.75rem;
    line-height: 2.75rem;
    background: rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px .3125rem 0 #FFF7E3;
    border-radius: 1.5rem;
    backdrop-filter: blur(.3rem);
    box-sizing: border-box;
    padding: 0 1.625rem;
    font-size: .875rem;
    font-family: AlibabaPuHuiTiM;
    color: #FFFFFF;
    text-shadow: 0 0 .125rem #A26D4A;
    text-stroke: .0625rem #A16D4A;
    -webkit-text-stroke: .0625rem #A16D4A;
    text-align: center;
}