.limbo_bg {
    background: var(--background) no-repeat 50%;
    background-size: cover;
    width: 100%;
    position: relative;
}

.limbo_ybLogo {
    width: 3.125rem;
    position: absolute;
    top: .8125rem;
    right: 0.25rem;
}

.limbo_mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(.125rem)
}

.limbo_title1 {
    position: absolute;
    bottom: 6.375rem;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 38px;
    font-size: .875rem;
    color: rgba(219, 190, 119, 1);
    line-height: 1.1875rem;
    font-weight: 500;
}

.limbo_title2 {
    position: absolute;
    bottom: 4.4375rem;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 1.1875rem;
    font-size: .6875rem;
    color: #FFFFFF;
    line-height: 1.1875rem;

}

.rate_btn {
    position: absolute;
    bottom: 9.8125rem;
    left: 50%;
    transform: translateX(-50%);
    width: 5.375rem;
    height: 5.375rem;
}