.loginPcPage {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
    position: absolute;
    filter: .125rem;
}

.loginPc_yblogo {
    position: absolute;
    right: 8.125rem;
    top: 3.625rem;
    width: 8.625rem;
}

.loginPcPage_form_mask {
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(.125rem);
}

/* 已登录 */

.loginPc_box {
    box-sizing: border-box;
    padding-top: 3.625rem;
}

.loginPc_box_title {
    font-size: 1rem;
    font-weight: 400;
    color: #999999;
    line-height: 1.375rem;
    text-align: center;
}

.loginPc_box_phone {
    font-size: 2rem;
    color: #1C1D1F;
    line-height: 3.4375rem;
    letter-spacing: .0625rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2px;
}

.loginPc_box_btn {
    margin-top: 1.25rem;
    width: 25rem;
    height: 4.125rem;
    margin: 4rem auto 0;
    cursor: pointer;
}

.loginPc_changeUser {
    font-size: .875rem;
    color: #A16D4A;
    line-height: 1.375rem;
    text-align: center;
    text-decoration: underline;
    margin-top: .875rem;
    cursor: pointer;
}

/* 未登录 */
.loginPcPage_form {
    width: 34.875rem;
    height: 30.625rem;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01mz50zV26FxyhAVFeU_!!6000000007633-2-tps-1118-980.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 3.5rem 4.8125rem;
}

.loginPcPage_title {
    height: 3.5rem;
    font-size: 1.625rem;
    font-family: AlibabaPuHuiTi_2_75_SemiBold;
    color: #1C1D1F;
    line-height: 3.4375rem;
    text-align: center;
}

.loginPcPage_title_mock {
    margin-bottom: 1.25rem;
}

.loginPcPage_phoneNum {
    width: 100%;
    height: 3.625rem;
    box-sizing: border-box;
    border-bottom: .0625rem solid rgba(28, 29, 31, 0.1);
    display: flex;
    align-items: center;
}

.loginPcPage_code {
    width: 100%;
    height: 3.625rem;
    box-sizing: border-box;
    border-bottom: .0625rem solid rgba(28, 29, 31, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginPcPage_code>img {
    width: 9rem;
    height: 2.375rem;
}

.loginPcPage_smsCode {
    width: 100%;
    height: 3.625rem;
    box-sizing: border-box;
    border-bottom: .0625rem solid rgba(28, 29, 31, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginPcPage_smsCode>span {
    height: 2.3125rem;
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #A16D4A;
    line-height: 2.3125rem;
    text-align: right;
}

.loginPcPage_btn {
    margin-top: 1.25rem;
    width: 25rem;
    height: 4.125rem;
    margin: 2.25rem auto 0;
    cursor: pointer;
}

.loginPc_agreement {
    text-align: center;
    margin-top: .875rem;
    color: rgba(161, 109, 74, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginPc_agreement>img {
    width: .875rem;
    height: .875rem;
    margin-right: .5rem;
    cursor: pointer;
}

.loginPc_agreement>span {
    cursor: pointer;
}

.loginPc_input {
    height: 1.5rem;
    width: 13.75rem;
    border: none;
    outline: none;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #1C1D1F;
    font-size: 1rem;
    background: transparent;
}

.loginPc_input::-webkit-input-placeholder {
    /* 使用webkit内核的浏览器 */
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #B0B2C4;
}

.loginPc_input:-moz-placeholder {
    /* Firefox版本4-18 */
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #B0B2C4;
}

.loginPc_input::-moz-placeholder {
    /* Firefox版本19+ */
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #B0B2C4;
}

.loginPc_input:-ms-input-placeholder {
    /* IE浏览器 */
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #B0B2C4;
}