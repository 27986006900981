.GamesToast_page {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2.1875rem;
    line-height: 2.1875rem;
    background: rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 .25rem 0 rgba(255, 247, 227, 1);
    border-radius: 1.25rem;
    backdrop-filter: blur(.625rem);
    font-size: .75rem;
    font-family: AlibabaPuHuiTiM;
    color: #FFFFFF;
    text-shadow: 0 0 .125rem #A26D4A;
    box-sizing: border-box;
    padding: 0 1.0625rem;
}