html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

.lineup_page {
    /* background-color: black; */
}

.lineup_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    /* object-fit: cover; */
}

.lineupVideo_loading_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(.25rem);
}

.lineupVideoLoading_box_failed {
    width: 1.75rem;
    height: 1.75rem;
}

.lineupVideoLoading_box_loading {
    width: 1.75rem;
    height: 1.75rem;
    animation: rotates 0.5s linear infinite;
}

@keyframes rotates {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.lineupVideo_loading_box>div {
    width: 100%;
    text-align: center;
    height: 1.4375rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.125rem;
    margin-top: .5rem;
}

.lineup_detail {
    position: absolute;
    top: 1rem;
    font-size: .75rem;
    font-family: AppleSystemUIFont;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}

.lineup_detail_into{
    font-size: .875rem;
    display: inline-block;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    box-sizing: border-box;
    padding: 0 1.375rem;
}
.lineup_desc{
    display: inline-block;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    box-sizing: border-box;
    padding: 0 1.375rem;
}

.lineup_position {
    width: auto;
    height: 1.5rem;
    line-height: 1.5rem;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    display: inline-block;
    margin-top: .375rem;
    font-size: .75rem;
    box-sizing: border-box;
    padding: 0 1.375rem;
}

.lineup_position>span{
    font-size: .9375rem;
}

.lingup_voice {
    width: 2.25rem;
    position: absolute;
    left: 1.875rem;
    bottom: 3rem;
}

.lineup_order {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01wXNqrO1ffcXSX0bQQ_!!6000000004034-2-tps-440-110.png") no-repeat;
    background-size: 100% 100%;
    width: 7.875rem;
    height: 1.9375rem;
    position: absolute;
    right: 1.875rem;
    bottom: 3rem;
    cursor: pointer;
}

/* 全屏按钮 */
.lineup_fullscreen_btn{
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 47%;
    left: 2.75rem;
    /* transform: translateY(-50%); */
    color: red;
    z-index: 1001;
}

/* 保存图片 */
.saveImg_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(11px);
}

.saveImg_content {
    width: 28.875rem;
    height: 16.25rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.saveImg_img {
    max-width: 100%;
    max-height: 100%;
    user-select: all !important;
}

.saveImg_title {
    width: 100%;
    height: 1rem;
    position: absolute;
    top: -1.875rem;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveImg_title>img {
    width: .875rem;
    height: .875rem;
    margin-right: .5rem;
}

.saveImg_title>span {
    font-size: .875rem;
    font-family: FZSHENGSKS_DAJW--GB1-0, FZSHENGSKS_DAJW--GB1;
    font-weight: normal;
    color: #FFDDA2;
}
.saveImg_closed{
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    right: -3.75rem;
    top: -2.5rem;
}

/* 加载游戏全屏按钮 */
.loadingGames_fullscreen_btn{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 2.75rem;
    left: 43%;
    /* transform: translateY(-50%); */
    z-index: 10001;
    transform: translateZ(1px);
}
.loadingGames_fullscreen_btn_false{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 10001;
    left:2.75rem;
    bottom: 43%;
    transform: translateZ(1px);
}

.lineup_bottom_mask{
    width: 100%;
    height: 4.875rem;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* 进度条 */
.lineup_bar{
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: 100%;
    font-size: .75rem;
    color: #FFFFFF;
}
.lineup_bar_box{
    width: calc(100% - 3.75rem);
    height: .25rem;
    background: rgba(255,255,255,0.3);
    margin: auto;
    border-radius: .3125rem;
}
.lineup_bar_content{
    height: 100%;
    background: linear-gradient(90deg, #DBBE77 0%, #A16D4A 100%);
    border-radius: .25rem;
}
.lineup_bar_num{
    padding-left: 1.875rem;
    margin-top: .125rem;
}

/* 跳过按钮 */
.lineup_skip {
    width: 5.25rem;
    height: 1.75rem;
    line-height:  1.75rem;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01fRqumo1HnoqTWm6Iv_!!6000000000803-2-tps-240-80.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 1.875rem;
    bottom: 3rem;
    box-sizing: border-box;
    padding-left: 1.3rem;
    font-size: .5785rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #DBBE77;
    text-shadow: 0px 0px 3px rgba(162, 109, 74, 0.32);
}

/* 延迟展示 */
.lineup_rtt_box{
    width: 4.125rem;
    height: 1.125rem;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    position: absolute;
    font-size: .75rem;
    box-sizing: border-box;
    padding-left: .25rem;
    z-index: 1001;
}
.lineup_rtt_box_h{
    transform: rotate(90deg) translate(1.5rem,1.5rem);
    top: 1.25rem;
    left: .625rem;
}
.lineup_rtt_box_s{
    left: 1.25rem;
    bottom: .625rem;
}