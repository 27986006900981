.mobile_modal_mask {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(0px);
    position: absolute;
    top: 0;
    left: 0;
}

.mobile_modal_box {
    width: 19.625rem;
    height: 12.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01pk9lSK1Dz25QAblO6_!!6000000000286-2-tps-1600-1000.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 1.25rem;
}

.mobile_modal_title {
    width: 100%;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 1rem;
    font-family: AlibabaPuHuiTi_2_75_SemiBold;
    color: #FFFFFF;
}

.mobile_modal_contents {
    width: 100%;
    height: 5.875rem;
    font-size: .8125rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #FFF7E3;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: 0 2.25rem;
}

.mobile_modal_btn {
    margin: auto;
    width: 6.375rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: .9375rem;
    font-family: COCA-COLACAREFONTKAITI--GB1-0, COCA-COLACAREFONTKAITI--GB1;
    font-weight: normal;
    color: #FFEEC3;
    text-shadow: 0 .0625rem 0px rgba(171, 31, 0, 0.4), 0 0 0 rgba(255, 255, 255, 0.71);
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01cAqshC1VJ2rR39Sxa_!!6000000002631-2-tps-560-160.png") no-repeat;
    background-size: 100% 100%;
}