.loadingGamesPc_page {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
}

.loadingGamesPc_video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    object-fit: cover;
    z-index: 1000;
    background: #000;
}

.loadingGamesPc_videoLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingGamesPc_videoLoading>img {
    width: 2.5rem;
    animation: rotates 0.5s linear infinite;
}

@keyframes rotates {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loadingGamesPc_videoLoading>div {
    margin-top: .5rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.0625rem;
}

.loadingGamesPc_loading {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 3.125rem;
    z-index: 1000;
    color: #fff;
}

.loadingGamesPc_voice {
    width: 3rem;
    height: 3.375rem;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    z-index: 1000;
}

/* 分享图片 */
.loadingGamesPc_saveimg_mask {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.loadingGamesPc_saveimg_box {
    width: 64.1875rem;
    height: 36.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    display: flex;
    justify-content: center;
}

.loadingGamesPc_saveimg_title {
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    font-family: FZSHENGSKS_DAJW--GB1-0, FZSHENGSKS_DAJW--GB1;
    font-weight: normal;
    color: #FFDDA2;
    text-align: center;
    position: absolute;
    left: 0;
    top: -2.25rem;
}

.loadingGamesPc_saveimg_title>img {
    width: .875rem;
    height: .875rem;
    margin-right: .375rem;
}
.loadingGamesPc_saveimg_closed{
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -3.75rem;
    right: -3.75rem;
    cursor: pointer;
}
.loadingGamesPc_saveimg_img{
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}