.introducePcPage {
    width: 100vw;
    min-height: 100vh;
    background-color: #000000;
    position: relative;
}

.introducePcVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
    /* object-fit: cover; */
}

.introducePc_videoLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.6);
}

.introducePc_videoLoading>img {
    width: 2.5rem;
    animation: rotates 0.5s linear infinite ;
}

@keyframes rotates {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.introducePc_videoLoading>div {
    margin-top: .5rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.0625rem;
}

.introducePc_time {
    width: auto;
    text-align: right;
    font-size: 1.125rem;
    font-family: AlibabaPuHuiTi_2_45_Light;
    color: #FFFFFF;
    line-height: 3.375rem;
    position: absolute;
    z-index: 100;
    right: 3.125rem;
    bottom: 3.125rem;
}

.introducePc_skip {
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01x5M9RQ1Ug7C3A3mvk_!!6000000002546-2-tps-248-92.png") no-repeat;
    background-size: 100% 100%;
    width: 7.25rem;
    height: 2.375rem;
    line-height: 2.375rem;
    color: #FFFFFF;
    font-size: 1rem;
    text-shadow: 0 0 .125rem rgba(162, 109, 74, 0.32);
    position: absolute;
    z-index: 100;
    right: 2.5rem;
    bottom: 3.75rem;
    box-sizing: border-box;
    padding-left: 1.75rem;
}

.introducePc_voice {
    width: 3rem;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
}