.backButtonPc {
    width: 9.25rem;
    height: 3.375rem;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN016zlDDC1BwclUVJzYE_!!6000000000010-2-tps-224-72.png") no-repeat;
    background-size: 100% 100%;
    line-height: 3.375rem;
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #DBBE77;
    text-shadow: 0px 0px .125rem #A26D4A;
    box-sizing: border-box;
    padding-left: 3.875rem;
    position: absolute;
    top: 2.5rem;
    left: 0;
    cursor: pointer;
}