.toast_box {
    width: max-content;
    height: 64px;
    line-height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 32px;
    box-sizing: border-box;
    padding: 0 32px 0 12px;
    word-break: break-all;
    display: flex;
}

.toast_box>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    margin-left: 12px;
    margin-top: 16px;
}

.toast_box>span {
    margin-left: 16px;
    font-size: 28px;
    font-family: AlibabaPuHuiTi_2_75_SemiBold;
    color: #FFFFFF;
}