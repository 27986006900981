.lineupPc_page {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
}

.lineupPc_video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    /* object-fit: fill; */
    background-color: black;
}

.lineupPc_videoLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(.25rem);
}
.lineupPc_video_img_play{
    width: 2.5rem;
}
.lineupPc_video_img_loading {
    width: 2.5rem;
    animation: rotates 0.5s linear infinite;
}

@keyframes rotates {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.lineupPc_videoLoading>div {
    margin-top: .5rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.0625rem;
}

.lineupPc_detail {
    position: absolute;
    top: 3.125rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #FFFFFF;
    line-height: 1rem;
    text-align: center;
    width: 100%;
}

.lineupPc_detail_into {
    font-size: 1rem;
    display: inline-block;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    padding: .25rem 1.375rem;
}

.lingupPc_desc {
    display: inline-block;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    padding: .25rem 1.375rem;
}

.lineupPc_position {
    width: auto;
    height: 2.125rem;
    line-height: 2.125rem;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    display: inline-block;
    margin-top: .625rem;
    font-size: .875rem;
    box-sizing: border-box;
    padding: 0 1.375rem;
}

.lineupPc_position>span {
    font-size: 1rem;
    color: rgba(219, 190, 119, 1);
    font-weight: 500;
}

.lingupPc_voice {
    width: 3rem;
    position: absolute;
    left: 4.875rem;
    bottom: 7.5rem;
}

.lineupPc_order {
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01jYKvnB23YnPtJQx5C_!!6000000007268-2-tps-384-96.png") no-repeat;
    background-size: 100% 100%;
    width: 10.375rem;
    height: 2.375rem;
    position: absolute;
    right: 4.875rem;
    bottom: 7.5rem;
    cursor: pointer;
}

/* 分享图片 */
.loadingGamesPc_saveimg_mask {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.loadingGamesPc_saveimg_box {
    width: 64.1875rem;
    height: 36.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    display: flex;
    justify-content: center;
}

.loadingGamesPc_saveimg_title {
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    font-family: FZSHENGSKS_DAJW--GB1-0, FZSHENGSKS_DAJW--GB1;
    font-weight: normal;
    color: #FFDDA2;
    text-align: center;
    position: absolute;
    left: 0;
    top: -2.25rem;
}

.loadingGamesPc_saveimg_title>img {
    width: .875rem;
    height: .875rem;
    margin-right: .375rem;
}

.loadingGamesPc_saveimg_closed {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -3.75rem;
    right: -3.75rem;
    cursor: pointer;
}

.loadingGamesPc_saveimg_img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.lineupPc_bottom_mask {
    width: 100%;
    height: 12.5rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* 进度条 */
.lineupPc_bar {
    position: absolute;
    bottom: 3.375rem;
    width: 100%;
}

.lineupPc_bar_box {
    width: calc(100% - 9.75rem);
    height: .25rem;
    margin: auto;
    background: rgba(255, 255, 255, 0.3);
    border-radius: .75rem;
}

.lineupPc_bar_content {
    height: 100%;
    background: linear-gradient(90deg, #DBBE77 0%, #A16D4A 100%);
    border-radius: .5rem;
}

.lineupPc_bar_num {
    margin-top: .5rem;
    color: #FFFFFF;
    padding-left: 4.875rem;
    font-size: .875rem;
    line-height: 1.125rem;
    text-shadow: 0 0 .125rem rgba(0, 0, 0, 0.5);
}

/* 跳过 */
.lineupPc_skip {
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01x5M9RQ1Ug7C3A3mvk_!!6000000002546-2-tps-248-92.png") no-repeat;
    background-size: 100% 100%;
    width: 7.25rem;
    height: 2.375rem;
    line-height: 2.375rem;
    position: absolute;
    z-index: 100;
    right: 4.875rem;
    bottom: 7.5rem;
    box-sizing: border-box;
    padding-left: 1.75rem;
    font-size: 1rem;
    color: #FFFFFF;
    text-shadow: 0 0 .125rem rgba(162, 109, 74, 0.32);
}

.lineupPc_rtt_box {
    position: fixed;
    background: rgba(0,0,0,0.3);
    left: 2.5rem;
    bottom: 1.25rem;
    width: 5.375rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    box-sizing: border-box;
    padding-left: .125rem;
    font-weight: 500;
}