html,body{
    width: 100%;
    height: 100%;
}

.introducePageContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.introduceVideo_box {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(.125rem);
}

.introduceVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.introduceVideo_transverse {
    width: 100%;
    height: 100%;
    border-top: .0625rem solid #000;
    border-bottom: .0625rem solid #000;
}

.introduceVideoLoading_box {
    width: 100%;
    height: 13.875rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
    padding-top: 5.4375rem;
    background-color: rgba(0, 0, 0, 0.6);
}
.introduceVideoLoading_box_failed{
    width: 1.6875rem;
    height: 1.6875rem;
}
.introduceVideoLoading_box_loading {
    width: 1.6875rem;
    height: 1.6875rem;
    animation: rotates 0.5s linear infinite ;
}


@keyframes rotates {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.introduceVideoLoading_box>div {
    width: 100%;
    text-align: center;
    height: .71875rem;
    font-size: 0.53125rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: .71875rem;
    margin-top: 0.375rem;
}

.introduce_change {
    position: absolute;
    top: 1.5rem;
    left: 1.3125rem;
    z-index: 100;
    width: auto;
}

.introduce_change>img {
    width: 1.0625rem;
    height: .875rem;
}

.introduce_change>span {
    font-size: .8125rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.1875rem;
    margin-left: .4375rem;
    vertical-align: bottom;
}

.introduce_change_vertical {
    line-height: 1.9375rem;
    text-align: center;
    background: rgba(197, 178, 137, 0.1);
    border-radius: 1.9375rem;
    border: .0625rem solid #DBBE77;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: calc(50% + 7.75rem);
    transform: translate(-50%);
}

.introduce_change_vertical>img {
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
}

.introduce_change_vertical>span {
    font-size: .8125rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.0625rem;
    margin-left: 0.4379rem;
}

.introduce_voice {
    width: 1.75rem;
    position: absolute;
    top: 1.3125rem;
    right: 7.1875rem;
    z-index: 100;
}

.introduce_voice_skip {
    top: 1.3125rem;
    right: 7.1875rem;
}

.introduce_voice_transverse {
    top: 1.1875rem;
    right: 1.3125rem;
}

.introducePage_time {
    width: 5.25rem;
    height: 1.1875rem;
    line-height: 1.1875rem;
    font-size: .8125rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    top: 1.75rem;
    right: 1.4375rem;
    z-index: 10;
}

.introducePage_skip {
    z-index: 100;
    width: 5.25rem;
    height: 1.75rem;
    line-height:  1.75rem;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01fRqumo1HnoqTWm6Iv_!!6000000000803-2-tps-240-80.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 1.3125rem;
    right: 1.3125rem;
    box-sizing: border-box;
    padding-left: 1.3rem;
    font-size: .5785rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #DBBE77;
    text-shadow: 0px 0px 3px rgba(162, 109, 74, 0.32);
}

.introducePage_skip_transverse {
    top: auto;
    bottom: 1.25rem;
}

.introducePage_time_transverse {
    top: auto;
    bottom: 1.25rem;
}
/* 全屏 */
img.loadingGames_fullscreen_btn{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 2.75rem;
    left: 43%;
    /* transform: translateY(-50%); */
    z-index: 1001;
}
img.loadingGames_fullscreen_btn_false{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 1001;
    left:2.75rem;
    bottom: 43%;
}

.loading_box {
    padding-top: 10rem;
    height: 100%;
}