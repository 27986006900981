html {
    background-color: #FFFFFF;
}

body {
    background-color: #ffffff;
}

.loginPage {
    width: 100%;
    height: auto;
}

.login_top {
    width: 100%;
    height: 216px;
    box-sizing: border-box;
    padding: 24px 0 0 56px;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01m8ZE4i1Pq7lSqCkPf_!!6000000001891-2-tps-1080-310.png") no-repeat;
    background-size: 100% 100%;
}

.login_back {
    width: 48px;
    height: 48px;
}

.login_title {
    margin-top: 72px;
    font-size: 48px;
    color: #1C1D1F;
    line-height: 48px;
    font-weight: bold;
}

/* 已登录状态 */
.login_box {
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    padding-top: 130px;
}

.login_box_img {
    width: 216px;
    height: 30px;
    margin: auto;
    display: block;
}

.login_box_phone {
    margin-top: 42px;
    text-align: center;
    color: rgba(28, 29, 31, 1);
    font-size: 52px;
    font-weight: bold;
}

.login_changeUser {
    font-size: 25px;
    font-weight: 400;
    color: #A16D4A;
    line-height: 32px;
    text-align: center;
    margin: 28px auto;
    text-decoration: underline;
    display: table;
}

/* 未登录的输入form */
.login_form {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 16px 56px 0;
}

.login_phoneNum {
    width: 100%;
    height: 94px;
    border-bottom: 1px solid rgba(28, 29, 31, 0.1);
    position: relative;
}

.login_phoneNum>input {
    border: none;
    outline: none;
    height: 90px;
    width: 300px;
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #1C1D1F;
    background-color: transparent;
    font-weight: 500;
}

.login_phoneNum>img {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 10px;
    margin-top: 22px;
}

.login_imgCode {
    width: 100%;
    height: 94px;
    border-bottom: 1px solid rgba(28, 29, 31, 0.1);
    position: relative;
}

.login_imgCode>input {
    border: none;
    outline: none;
    height: 90px;
    width: 300px;
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #1C1D1F;
    background-color: transparent;
    font-weight: 500;
}

.login_imgCode>img {
    width: 208px;
    height: 56px;
    float: right;
    margin-top: 19px;
}

.login_messageCode {
    width: 100%;
    height: 94px;
    border-bottom: 1px solid rgba(28, 29, 31, 0.1);
    position: relative;
}

.login_messageCode>input {
    border: none;
    outline: none;
    height: 88px;
    width: 300px;
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #1C1D1F;
    background-color: transparent;
    font-weight: 500;
}

.login_messageCode>span {
    float: right;
    text-align: right;
    height: 92px;
    line-height: 92px;
    font-size: 24px;
    font-family: AppleSystemUIFont;
    font-weight: 500;
}

.login_btn {
    width: 640px;
    height: 101px;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01JbGQaS1sYpfmqLGVg_!!6000000005779-2-tps-1842-292.png") no-repeat;
    margin: 70px auto 0;
    background-size: 100% 100%;
    /* text-align: center;
    line-height: 97px;
    border-radius: 24px;
    font-size: 43px;
    color: #FFFFFF;
    letter-spacing: 3px;
    text-shadow: 0px 0px 13px rgba(162, 109, 74, 0.8);
    -webkit-text-stroke: 2px #A16D4A;
    text-stroke: 2px #A16D4A;
    font-weight: 600; */
}


.login_agreement {
    text-align: center;
    color: rgba(161, 109, 74, 1);
    font-size: 24px;
    margin-top: 32px;
}

.login_agreement>img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 20px;
}

.login_agreement>span {
    vertical-align: middle;
}


.login_input::-webkit-input-placeholder {
    /* 使用webkit内核的浏览器 */
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #B0B2C4;
}

.login_input:-moz-placeholder {
    /* Firefox版本4-18 */
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #B0B2C4;
}

.login_input::-moz-placeholder {
    /* Firefox版本19+ */
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #B0B2C4;
}

.login_input:-ms-input-placeholder {
    /* IE浏览器 */
    font-size: 32px;
    font-family: AppleSystemUIFont;
    color: #B0B2C4;
}