html {
    background-color: #ffffff;
}

.homePage {
    background: var(--background) no-repeat 50%;
    background-size: cover;
    width: 100%;
    position: absolute;
}

.home_ybLogo{
    width: 3.125rem;
    position: absolute;
    top: .8125rem;
    right: 0.25rem;
}

.home_backBtn {
    width: 6.0625rem;
    height: 2rem;
    line-height: 2rem;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01gmVcIL1TGdXZTbx4w_!!6000000002355-2-tps-280-90.png") no-repeat;
    background-size: 100% 100%;
    font-size: .6875rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #DBBE77;
    text-shadow: 0px 0px .0625rem #A26D4A;
    box-sizing: border-box;
    padding-left: 2.75rem;
    position: absolute;
    top: 1.75rem;
}

.home_textImg {
    height: 720px;
    position: absolute;
    top: 70px;
    right: 20px;
}

.home_detail_app {
    font-size: .6875rem;
    color: #FFFFFF;
    line-height: 1.1875rem;
    text-align: center;
    position: absolute;
    bottom: 5.0625rem;
    left: 50%;
    transform: translateX(-50%);
}

.home_detail_h5 {
    position: absolute;
    bottom: 3.125rem;
    left: 50%;
    transform: translateX(-50%);
    width: 20rem;
    border-radius: .5rem;
}

.home_detail_h5>div {
    text-align: center;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #FFFFFF;
    line-height: 1rem;
}

.home_detail_h5>div:last-child {
    margin-top: .125rem;
}

.home_btn {
    /* background: var(--button) no-repeat; */
    /* background-size: 100% 100%; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8rem;
    width: 16.9375rem;
    /* height: 3.0625rem; */
}

.home_ali {
    width: 8.125rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 2rem;
    position: absolute;
    bottom: .875rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: .6875rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
}