.homePcPage {
    width: 100vw;
    min-height: 100vh;
    /* min-height: 67.5rem; */
    /* background: var(--background) no-repeat 50%;  */
    /* background-size: cover; */
    position: relative;
}

.homePc_background{
    width: 100vw;
    height: 100vh;
    min-height: 67.5rem;
    object-fit: cover;
}

.homePc_yblogo{
    width: 8.625rem;
    position: absolute;
    left: 8.125rem;
    top: 3.625rem;
}


.homePc_btn {
    width: 25.125rem;
    position: absolute;
    left: 6.625rem;
    bottom: 12.5rem;
    cursor: pointer;
}

.homePc_ali {
    font-size: .875rem;
    color: #FFFFFF;
    line-height: 1.5625rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.75rem;
    bottom: 1.875rem;
    color: rgba(255, 255, 255, 0.8);
}
.homePc_position_btn{
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.homePc_btns{
    width: 18.75rem;
    position: absolute;
    /* bottom: 6.25rem; */
    top: 40.625rem;
    /* top: 43.75rem; */
    /* top: 80%; */
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.homePc_ybLogo{
    width: 7.5rem;
    position: absolute;
    top: 1.75rem;
    right: 3rem;
}