html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

.loadingGames_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loadingGames_video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: #000;
    object-fit: cover;
    border-top: .0313rem solid #000;
    border-bottom: .0313rem solid #000;
}

.loadingGamesVideo_loading_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
}

.loadingGamesLoading_box_failed {
    width: 1.75rem;
    height: 1.75rem;
}

.loadingGamesVideoLoading_box_loading {
    width: 1.75rem;
    height: 1.75rem;
    animation: rotates 0.5s linear infinite;
}

@keyframes rotates {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loadingGamesVideo_loading_box>div {
    width: 100%;
    text-align: center;
    height: 1.4375rem;
    font-size: .75rem;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #DBBE77;
    line-height: 1.125rem;
    margin-top: .5rem;
}

.loadingGames_detail {
    position: absolute;
    top: 1.125rem;
    font-size: .75rem;
    font-family: AppleSystemUIFont;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    z-index: 1001;
}

.loadingGames_detail>span {
    width: auto;
    height: 1.5rem;
    line-height: 1.5rem;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 52%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(0);
    display: inline-block;
    font-size: .75rem;
    box-sizing: border-box;
    padding: 0 1.375rem;
}

.loadingGames_voice {
    width: 2rem;
    position: absolute;
    right: 1.25rem;
    top: .75rem;
    z-index: 1001;
}

/* 保存图片 */
.saveImg_box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(11px);
}

.saveImg_content {
    width: 28.875rem;
    height: 16.25rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.saveImg_img {
    max-width: 100%;
    max-height: 100%;
    user-select: all !important;
}

.saveImg_title {
    width: 100%;
    height: 1rem;
    position: absolute;
    top: -1.875rem;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveImg_title>img {
    width: .875rem;
    height: .875rem;
    margin-right: .5rem;
}

.saveImg_title>span {
    font-size: .875rem;
    font-family: FZSHENGSKS_DAJW--GB1-0, FZSHENGSKS_DAJW--GB1;
    font-weight: normal;
    color: #FFDDA2;
}
.saveImg_closed{
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    right: -3.75rem;
    top: -2.5rem;
}

/* 全屏 */
.loadingGames_fullscreen_btn{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 2.75rem;
    left: 43%;
    /* transform: translateY(-50%); */
    z-index: 10001;
    transform: translateZ(1px);
}
.loadingGames_fullscreen_btn_false{
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 10001;
    left:2.75rem;
    bottom: 43%;
    transform: translateZ(1px);
}