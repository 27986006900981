.modal_mask{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
}
.modal_box {
    width: 17.375rem;
    height: 10.875rem;
    /* background: url("https://img.alicdn.com/imgextra/i4/O1CN016RmPMn202msop7YW3_!!6000000006792-2-tps-800-500.png") no-repeat; */
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01pk9lSK1Dz25QAblO6_!!6000000000286-2-tps-1600-1000.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.modal_title {
    font-size: .9375rem;
    font-family: AlibabaPuHuiTi_2_75_SemiBold;
    color: #FFFFFF;
    line-height: 1.3125rem;
    letter-spacing: .0625rem;
    text-align: center;
    margin-top: 1.125rem;
}

.modal_contents {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: .6875rem;
    font-family: AlibabaPuHuiTi_2_65_Medium;
    color: #FFF7E3;
    line-height: 1rem;
    box-sizing: border-box;
    padding: 0 1.75rem;
}

.modal_btn {
    width: 5.6875rem;
    height: 1.8125rem;
    line-height: 1.8125rem;
    text-align: center;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01cAqshC1VJ2rR39Sxa_!!6000000002631-2-tps-560-160.png") no-repeat;
    background-size: 100% 100%;
    font-size: .8125rem;
    font-family: COCA-COLACAREFONTKAITI--GB1-0, COCA-COLACAREFONTKAITI--GB1;
    font-weight: normal;
    color: #FFEEC3;
    margin: auto;
    text-shadow: 0 .0625rem 0 rgba(171, 31, 0, 0.4), 0 .0625rem 0 rgba(255, 255, 255, 0.71);
}