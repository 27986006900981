.backButton {
    width: 6.875rem;
    height: 2.25rem;
    line-height: 2.25rem;
    position: absolute;
    left: 0;
    top: 1rem;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01R6SJdz1wjbRi6iEH3_!!6000000006344-2-tps-280-90.png") no-repeat;
    background-size: 100% 100%;
    font-size: .875rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #DBBE77;
    text-shadow: 0 0 .0625rem #A26D4A;
    box-sizing: border-box;
    padding-left: 3rem;
}